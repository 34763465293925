import React from "react";

import CategoryTemplate from "../../../../../lib/templates/category";
import { SectionType, Section } from "../../../../../lib/templates/category/typings";

import { transformContentstackPropsToSectionProps as transformCategoryIntro } from "../../../../../lib/sections/category/intro/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformArticleList } from "../../../../../lib/sections/category/article-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsItemList } from "../../../../../lib/sections/news-item-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsHeader } from "../../../../../lib/sections/news/header/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPromo } from "../../../../../lib/sections/promo/transforms/contentstack";

import { TemplateContext, ContentstackSection } from "./typings";
import Meta from "../../../../../lib/components/meta";
import { openGraphImageUrl, twitterImageUrl } from "../../../../../lib/assets/share";
import { useMetaTitle } from "../../../../utils/use-meta-title";
import { graphql, PageProps } from "gatsby";
import transformAll from "../../transforms/all-tags-query-node-to-default-template-context-data";
import { ContentstackArticleExcerptFragment } from "../../../../../generated/graphql";

export interface TagArticles extends ContentstackArticleExcerptFragment {
  date: Date;
  category: Array<{ title: string; uid: string }>;
  article_tags: Array<{ uid: string }>;
}
interface PageData {
  all: {
    nodes: any;
  };
  articles: { nodes: TagArticles[] };
}
type Props = PageProps<PageData, TemplateContext>;

const byPublishedLocale =
  (locale: string) =>
  (node: any): boolean =>
    node.publish_details.locale === locale;

const ContentType: React.FC<Props> = ({ pageContext, data: __data }) => {
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const tagNode = __data.all.nodes.filter(byContextLocale)[0];
  const tagArticles = __data.articles.nodes;
  const data = transformAll(tagNode, tagArticles);
  let sections = data.sections;
  const firstSection = data.sections[0];

  if (firstSection && firstSection.type === SectionType.NEWS_HEADER && !firstSection.props.intro) {
    sections = [
      {
        ...firstSection,
        props: {
          ...firstSection.props,
          intro: {
            title: data.title,
            blurb: data.description,
          },
        },
      },
      ...sections.slice(1),
    ];
  } else {
    sections = [
      {
        type: SectionType.CATEGORY_INTRO,
        props: {
          title: data.title,
          blurb: data.description,
        },
      },
      ...sections,
    ];
  }

  return (
    <React.Fragment>
      <Meta
        title={useMetaTitle(data.title)}
        description={data.description}
        openGraph={{
          image: {
            url: data.image ? data.image.url : openGraphImageUrl,
          },
        }}
        twitter={{
          image: {
            url: data.image ? data.image.url : twitterImageUrl,
          },
        }}
      />
      <CategoryTemplate sections={dataSectionsToSections(sections)}></CategoryTemplate>
    </React.Fragment>
  );
};
export default ContentType;

const dataSectionsToSections = (dataSections: ContentstackSection[]): Section[] => {
  return dataSections
    .map((csSection, index) => {
      let props;

      switch (csSection.type) {
        case SectionType.CATEGORY_INTRO:
          props = transformCategoryIntro(csSection.props);
          break;

        case SectionType.NEWS_HEADER:
          props = transformNewsHeader(csSection.props);
          break;

        case SectionType.PROMO:
          props = transformPromo(csSection.props);
          break;

        case SectionType.CATEGORY_ARTICLE_LIST:
          // WORKAROUND: not sure how to get around this in any other way
          props = null as any;
          break;

        case SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK:
          props = transformArticleList(csSection.props);
          break;

        case SectionType.NEWS_ITEM_LIST:
          props = transformNewsItemList(csSection.props);
          break;
      }

      if (!props) {
        console.warn(`Unable to transform section. (type: ${csSection.type})`);
        return null;
      }

      return {
        type: csSection.type,
        key: `section_${index}`,
        props,
      };
    })
    .filter((section): section is Section => section !== null);
};

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  # query($language: String!, $url: String!, $path: String!) {
  # use our workaround and alias null result to something short (_)
  # that doesn't expose what it does and saves few bytes
  #_: contextChangeNotInvalidingQueryWorkaround(path: $path)

  query ($language: String!, $url: String!, $uid: String!) {
    all: allContentstackTags(filter: { publish_details: { locale: { eq: $language } }, url: { url: { eq: $url } } }) {
      nodes {
        publish_details {
          locale
        }
        uid
        title
        url {
          url
        }
        category_description
        meta_image {
          uid
          url
        }
      }
    }

    articles: allContentstackArticles(
      filter: {
        publish_details: { locale: { eq: $language } }
        article_tags: { elemMatch: { uid: { eq: $uid } } }
        hide_from_lol_com: { eq: false }
      }
      sort: { fields: date, order: DESC }
      limit: 50
    ) {
      nodes {
        date
        ...ContentstackArticleExcerpt
        category {
          uid
        }
        article_tags {
          uid
        }
      }
    }
  }
`;
