import { SectionType } from "../../../../lib/templates/category/typings";
import { TemplateContextData, ContentstackSection } from "../../categories/templates/default/typings";
import { ContentstackAllTagsQuery as Query } from "../../../../generated/graphql";
import { ImageAssetUrl } from "../../assets/typings";
import { articleDataToArticle } from "../../categories/transforms/modular-blocks/filtered-news";
import notEmpty from "../../../utils/not-empty";
import { TagArticles } from "../templates/default";

const transform = (node: Query["all"]["edges"][0]["node"], articles: TagArticles[]): TemplateContextData => {
  // transform sections
  const sections: ContentstackSection[] = [];

  // Article list section
  sections.push({
    type: SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK,
    props: {
      articles: articles.map((articleData) => articleDataToArticle(articleData)).filter(notEmpty),
      terms: [],
    },
  });

  return {
    uid: node.uid,
    title: node.title,
    description: node.category_description || undefined,
    image: !node.meta_image
      ? null
      : {
          url: node.meta_image.url as ImageAssetUrl,
        },
    sections,
  };
};

export default transform;
